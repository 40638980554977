import React from 'react';

export interface PreloadedData {
  [x: string]: PreloadedDataValue;
}

export type PreloadedDataValue = string | number | boolean | undefined | null | PreloadedData;

export type PreloadedDataContextValue = {
  preloadedData: PreloadedData | undefined;
  setPreloadedData: (arg: PreloadedData) => void;
};

const PreloadedDataContext = React.createContext<PreloadedDataContextValue | undefined>(undefined);

export { PreloadedDataContext };
