import Cookies from 'universal-cookie';
import { LoggerRequestContext } from 'logger/types';

const getCookies = () => new Cookies();

const getLoggerRequestContext = (): LoggerRequestContext => ({
  clientUserAgent: window.navigator.userAgent,
  customerId: getCookies().get('customerId'),
});

export default getLoggerRequestContext;
