import { useContext } from 'react';
import { PreloadedDataContext } from './PreloadedDataContext';

const usePreloadedData = () => {
  const preloadedDataContext = useContext(PreloadedDataContext);

  if (preloadedDataContext === undefined) {
    throw new Error('usePreloadedData must be used within a PreloadedDataProvider');
  }

  return preloadedDataContext;
};

export default usePreloadedData;
