import React, { FC, ReactNode, useMemo, useState } from 'react';
import {
  PreloadedDataContext,
  type PreloadedData,
  type PreloadedDataContextValue,
} from './PreloadedDataContext';

const PreloadedDataProvider: FC<{
  children: ReactNode;
  preloadedData: PreloadedData | undefined;
}> = ({ children, preloadedData: initialData }) => {
  const [preloadedData, setPreloadedData] = useState(initialData);

  const providerValue: PreloadedDataContextValue = useMemo(
    () => ({
      preloadedData,
      setPreloadedData,
    }),
    [preloadedData, setPreloadedData],
  );

  return (
    <PreloadedDataContext.Provider value={providerValue}>{children}</PreloadedDataContext.Provider>
  );
};

export default PreloadedDataProvider;
